import React, { useState } from "react";
import "./producttwo.css";
import Product2 from "../../assets/decomposer.png";

const ProductTwo = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <div className="product1">
        <div className="product1-img">
          <img src={Product2} alt="decomposer" />
        </div>
        <div className="product1-title" onClick={toggleModal}>
          <p>decomposer</p>
        </div>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={toggleModal}>
                &times;
              </span>
              <h1>Decomposer</h1>
              <div className="about-product">
                <div className="product-intro">
                  <div className="productone">
                    <div className="one-content1">
                      <div className="one-content-left">
                        <ul>
                          <h3>इलमी डिकम्पाेजर नै किन?</h3>
                          <li>
                            सजिलै कुहाउन मिल्ने कार्बनिक वस्तुहरुको जिवाणुजन्य
                            (Microbial) द्रुत विघटन प्रकियाबाट निश्चित समयभित्र
                            कुहाई गुणस्तरीय कम्पोष्ट मल बनाउन प्रयोग गरिनेजैविक
                            पदार्थ नै डिकम्पोजर हो ।
                          </li>
                          <li>
                            बाली सघनतालेप्रांगारिक मलको आवश्यत्ता कैयन गुणा बढेर
                            गएको छ । तरकारी बाली, कौशी खेती, फलफू ल नर्शरीहरुको
                            बढ्दो आवश्यत्ता पुरा गर्न पूर्णरूपमा कुहिएको मल छिटो
                            छरितो तरिकाले तयार पार्नु पने चुनौति हाम्रा अगाडी छ
                            । यस राष्ट्रिय आवश्यकतालाई ध्यान दिदै इलमी एग्रो
                            एण्ड टेक प्रा. लि.ले इलमी रैथानेडिकम्पोजर बजारमा
                            ल्याएको छ । यो डिकम्पोजर फाइदाजनक रैथाने शूक्ष्म
                            जिवाणु तथा ढुसीहरुबाट बानइएको हो । परम्परागत
                            तरिकाबाट जाडो याममा गोठेमल कुहिन ६ देखि ७ महिना
                            लाग्छ भने न्यानो याममा कम्तिमा ४ महिना । यो
                            डिकम्पोजरले कार्बनिक पदार्थहरुको जिवाणुजन्य विघटन
                            प्रकियाबाट द्रुत विघटन गराई गुणस्तरीय मल लगभग १
                            महिना भित्रै तयार पारी प्रांगारिक मल बनाउने
                            प्रकियामा रुपान्तरण गर्न सकिन्छ । अर्थात लगभग १
                            महिनामा राम्रोसँग पाकेको मल बालीनालीमा प्रयोग गर्नका
                            लागि तयार पार्न सकिन्छ ।
                          </li>
                          <li>
                            डिकम्पोजरले कार्बनिक पदार्थको जिवाणुजन्य
                            प्रक्रियाबाट बिघटन गरी मलमा परिणत गर्दछ । यो बिघटन
                            प्रक्रिया ठीकसंग संचालन गर्न तथा गुणस्तरयुक्त
                            संतुलित मल तयार पार्न कार्बनिक पर्दाथ र नाईट्रोजनको
                            श्रोतको अनुपात ठीकसंग प्रयोग गर्नु पर्दछ । सामान्यता
                            ३० भाग कार्बनिक पर्दाथमा १ भाग नाईट्रोजनको श्रोत (
                            गाईवस्तुको मल, गहुँत अथवा युरीया मल समेत प्रयोग गर्न
                            सकिन्छ । यदि रसायनमुक्त प्राङ्गारीक मलको प्रयोग
                            गर्ने उद्देश्य हो भने युरीयाको प्रयोग नगर्नुबेश ।
                          </li>
                          <h3>द्त तरिकाले प्राङ्गारिक र गोठेमल कसरी बनाउने</h3>
                          <li>
                            गाईवस्तुको गोबर र गहँुत, स्याउला/सोत्तर, सुकेका
                            पातपतिङ्गर, खेर गईरहेका झारपात बालीको अवशेष, जस्ता
                            कु हाउने मिल्ने बस्तुहरुको बिघटन गरी परम्परागत रुपमा
                            प्रयोग गरिने गोठेमल नेपालमा सबभन्दा प्रचलित प्रागरिक
                            मल हो । डिकम्पोजरको प्रयोग गरि द्रुत
                            तरिकालेप्राङ्गारिक मल बनाउन सकिन्छ । यस प्रकारको मल
                            जमिनमाथि थूपारेर वा खाल्डोमा तयार पार्न सकिन्छ ।
                            जुनसुकै तरिका प्रयोग गर्दा पनि प्लाष्टिकले हावा
                            नछिर्ने गरी राम्ररी छोप्नु पर्दछ । जमिनमाथि थूपारेर
                            मल कम लागतमा नै तयार पार्न सकिन्छ किनभने यसको लागी
                            खाल्डो खन्नु पर्ने खर्चको बचत हुन्छ । तर जमिनमाथि
                            तयार पारिएको मल वर्षाको भललेबगाएर मल खातबाट निस्किने
                            विरुवाको खाद्य तत्व मिश्रित झोल मलको नोक्शानी
                            हुनसक्छ । जुनसुकैतरिकाबाट मल तयार पारेतापनि मलको
                            खातलाई सुर्यको सीधा घाम, पानी, हावाबाट जोगाउन पर्दछ
                            र यसका लागि मल माथी छाना वा प्लाष्टिक लगाउनु वेश
                            हुन्छ ।
                          </li>
                          <h3>
                            परम्परागत गोठेमललाई डिकम्पोजरले कसरी सुधार गर्छ ?
                          </h3>
                          <li>
                            परम्परागत रुपमा तयार पारिने गोठेमल कुहिने समय
                            हावापानी, मल बनाउन प्रयोग हुनेबनष्पतिजन्य कु राहरुमा
                            भर पर्छ । किसानहरुले सामान्यतया वर्षे बाली मकै , धान
                            तथा आलु, तरकारी र हिउदेबाली गरी वर्षको दुईपटक
                            गोठेमलको प्रयोग गर्ने गर्दछन । समयको अभावले कतिपय
                            अवस्थामा किसानहरु अांशिकरुपमा कुहिएको मल प्रयोग गर्न
                            पनि बाध्य छन । यस्तो मलको प्रयोगबाट खुम्रे कीरा,
                            रातो कमिला तथा माटोमा बस्ने अन्य हानिकारक कीराहरुको
                            प्रकोप बढ्न गई बालीनालीमा ठू लो नोक्शानी हुन्छ ।
                            डिकम्पोजरको प्रयोग गरी तयार पारिएको मल पूर्णरुपमा
                            कुहिनेहुनाले कीराको समस्या हुदैनन् बरु यसरी तयार
                            पारीएकाेमल विरुवाको खाद्य तत्वहरु बढी मात्रामा
                            हुन्छन् ।
                          </li>
                          <h3>प्रभाव अवधि</h3>
                          <li>
                            डिकम्पाेजर सूर्यको सिधा प्रकाश, तापक्रम तथा
                            आर्द्रताबाट सुरक्षित भण्डारण गरीएको खण्डमा उत्पादन
                            भएको मितिदेखि १२ महिनासम्म प्रभावकारी रहन्छ ।
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="one-content2">
                      <h1>प्रयोग विधि</h1>

                      <div className="one-content-left">
                        <ul>
                          <h3>गोठे मल बनाउन</h3>
                          <li>
                            ५ लीटर डिकम्पोजरलाई ५० लीटर पानीमा घोली १००० किलो
                            गोठेमलमा राम्रोसंग मिसाउनुहोस र हावा नछिर्ने गरी
                            प्लाष्टिकले छोपेर राख्नुहोस ।
                          </li>
                          <li>
                            यो मल राम्ररी नकुिहदासम्म हप्ताको एक पटक पल्टाउनु
                            पर्दछ ।
                          </li>
                          <li>
                            यसरी लगभग १ महिनामा गुणस्तरीय तथा वातावरण मैत्री
                            गोठेमल प्रयोग गर्न तयार हुन्छ ।
                          </li>
                          <h3>कम्पोष्टमल बनाउन</h3>
                          <li>
                            शुरुमा २ लीटर खुदो वा २ किलो गुड्/ सख्खरको लेदो (२
                            किलो गुड्/सख्खर पानीमा घोलेर तयार पार्ने) तयार
                            पार्नुहोस ।
                          </li>
                          <li>
                            ५ लीटर डिकम्पोजर र २ लीटर खुदो वा २ किलो
                            गुड्/सख्खरको लेदो ५० लीटर पानीमा मिसाउनु होस ।
                          </li>
                          <li>
                            यसरी तयार पारेको झोल १००० किलो पातपतिङ्घर, स्याउला
                            सोत्तरमा राम्रोसंग मिसाई प्लाष्टिकले हावा नछिर्ने
                            गरी छोपेर राख्नुहोस ।
                          </li>
                          <li>
                            यस्तो मललाई हप्ताको एक पटक पल्टाउनु पर्दछ र लगभग १
                            महिनामा गुणस्तरीय तथा वातावरण मैत्री मल पूर्णरूपमा
                            तयार हुन्छ ।
                          </li>
                          <h3>किचन कम्पोष्ट बनाउन</h3>
                          <li>
                            भान्साबाट निस्किने खानाको अवशेष, कुहिने कुरा,
                            करेसाबारी बाट निस्किएको झारपात आदि कुहाउन हरेक १०
                            किलोको लागी ५० मि.ली. डिकम्पोजर प्रयोग गर्नुहाेस् ।
                          </li>
                          <li>
                            कु हाउनु पर्ने कु राहरू मल बनाउने खाल्डो, कम्पोष्ट
                            बिन वा अन्य भाडामा राखिसकेपछि डिकम्पोजर राम्रोसँग
                            मिसाई हावा नछिर्ने गरी छोपेर राख्नुुहोस ।
                          </li>
                          <li>
                            यस्तो मललाई प्रत्येक हप्तामा पल्टाउनु पर्दछ र लगभग १
                            महिनामा गुणस्तरीय तथा वातावरण मैत्री कम्पोष्ट मल
                            पूर्णरूपमा तयार हुन्छ ।
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductTwo;
